import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Switch } from "@headlessui/react";
import { Tooltip } from 'react-tooltip';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import styled from "@emotion/styled";
import { mapEdgesToNodes } from "../lib/helpers";
import { toPlainText } from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA2";
import ModalTeamMembers from "../components/Modal/ModalTeamMembers";
const StyledContent = styled.div`
.react-tabs__tab--selected {
  color:#304251;
  font-weight:bold;
}
`;

const cost = {
  starter: [
    {
      year: "$650",
      month: "$1,950",
      orginalPrice: "$360",
    },
  ],
  grow: [
    {
      year: "$285",
      month: "$855",
      orginalPrice: "$900",
    },
  ],
  platform: [
    {
      year: "$150",
      month: "$450",
      orginalPrice: "$3,000",
    },
  ],
};
const pirceDropdown = {
  starter: [
    {
      name: "Access to our library of support articles",
    },

    {
      name: "Customer support in real time",
    },

    {
      name: "Hundreds of smart filters available with every integration",
    },
  ],
  grow: [
    {
      name: "Free account set up",
    },

    {
      name: "Support with onboarding and activation of integrations",
    },
    {
      name: "Access to analytics on how your integration is performing",
    },
    {
      name: "Custom admin permission - control your users",
    },
  ],
  platform: [
    {
      name: "Priority customer support - 5X faster response",
    },

    {
      name: "1 hour of free customization",
    },
    {
      name: "Monthly tech stack review with our Account Executive (tech experts)",
    },
    {
      name: "Access to our new integrations before general public",
    },
    {
      name: "Exclusive custom service prices",
    },
    {
      name: "Access to client-driven LIVE monthly mastermind ",
    },
  ],
};
const Page = ({ data }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [navDropdownOpen1, setnavDropdownOpen1] = useState(false);
  const navClickHandler1 = () => {
    setnavDropdownOpen1(!navDropdownOpen1);
  };
  const [navDropdownOpen2, setnavDropdownOpen2] = useState(false);
  const navClickHandler2 = () => {
    setnavDropdownOpen2(!navDropdownOpen2);
  };
  const [navDropdownOpen3, setnavDropdownOpen3] = useState(false);
  const navClickHandler3 = () => {
    setnavDropdownOpen3(!navDropdownOpen3);
  };
  // const [switchActive, switchActive] = useState(false);
  // const navClickHandler3 = () => {
  //     setnavDropdownOpen3(!switchActive);
  // };
  const tier = {
    starter: [
      {
        name: "Starter",
      },
    ],
    grow: [
      {
        name: "Grow",
      },
    ],
    platform: [
      {
        name: "Platform (Unlimited)",
      },
    ],
  };
  const { props, errors } = data;
  const page = props && props.page;

    
    const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 300) : str;
    };

  const subServiceNodes =
    data && data.subServices && mapEdgesToNodes(data.subServices);
    const [tabIndex, setTabIndex] = useState(0);

    const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  const [slideIndex, setSlideIndex] = useState(0);
  return (
    <Layout serviceType="wealth">
      <SearchEngineOptimization
        title="Our Services | Dark Horse Private Wealth"
        description="Say no to added fees and taxes and yes to building your private wealth—partner with Dark Horse CPAs. Call us today to invest in your future."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative overflow-hidden pt-20 pb-20 md:pt-32 md:pb-36">
        <div className="container">
          <div className="mb-6 grid items-center gap-y-14 md:grid-cols-2 md:gap-x-10 lg:gap-x-28">
            <div className="order-last md:order-first">
              <div className="">
                <h2 className="">Private Wealth Services</h2>
                <p>
                Taxes reduce your return on investment. While you can't totally eliminate the taxes related to your investments, you can substantially reduce or defer them with proper tax planning. That's what Dark Horse Private Wealth is all about...minimizing the taxes that eat away at your investments. We build financial plans and investment strategies for our clients that equally weigh tax efficiency and overall investment performance.
                </p>
              </div>
              <ButtonSolid
                href="/schedule-consult/"
                text="Get Started"
                altStyle={3}
                className="w-full md:w-auto"
              />
            </div>
            <div className="order-first md:order-last">
              <div className="relative ml-8 mr-4 lg:mx-0">
                
                <div className="relative z-10">
                  <GatsbyImage
                    image={data.serviceHero.childImageSharp.gatsbyImageData}
                    className="z-0 w-full rounded-4xl lg:min-h-[450px]"
                  />
                  
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* break */}
      <section className="bg-primary-600 pt-20 md:pt-32 md:pb-[300px] zigZagBg">
        <div className="container">
        <StyledContent>
        <Tabs
					selectedIndex={tabIndex}
					onSelect={(index) => {
						setTabIndex(index);
						// clickHandler(index);
					}}
			>
          <div className="grid grid-cols-9 gap-4 relative items-center place-items-center flex items-stretch h-full content-center mb-10">
            <div className="col-span-9 md:col-span-3 bg-white rounded-xl p-[25px] relative items-center place-items-center">
            <TabList className="mb-[20px] p-[5px] block border-0"
     >
				<Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Investment Management</Tab>
				<Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Low-Cost Investment Models</Tab>
				<Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Technology</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Behavioral Finance Coaching</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Tax Strategies</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Risk Management</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Systematic Rebalancing</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Tax Loss Harvesting</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Asset Location Optimization</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Tax-Efficient Income Planning</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Estate Planning</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Financial Planning</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Alternative Investments</Tab>
        <Tab className="block border-0 text-primary-500 mb-2 cursor-pointer mb-[16px]">Ongoing, Proactive Support for Your Changing Needs</Tab>
			</TabList>
            </div>
            <div className="col-span-9 md:col-span-6 bg-white rounded-xl p-[25px] relative items-center place-items-center align-middle content-center">
            <div className="self-center px-10 pt-[15%] -pb-[15%]">
            <TabPanel><h3>Investment Management</h3>Traditional investment strategists seek to outperform markets by gaining an edge over all other market players in finding and exploiting projections in perceived future value. Without fail, studies by investment firms and analysts have demonstrated that these tactics are expensive to employ and are frequently fruitless. Given the high failure rate of this methodology, investors risk losing out on the robust returns offered by exposure to the markets. Our more modern, data-driven investment approach enables us to tap into the strength of the capital markets with greater efficiency.</TabPanel>
            <TabPanel><h3>Low-Cost Investment Models</h3>DHPW utilizes a research-based investment approach to create all-weather strategies tailored to our clients' goals. You’ll gain access to cost-effective, institutional-quality funds with some of the largest global asset managers. We charge a single, low annual fee that is fully transparent and disclosed in advance of any engagement.</TabPanel>
            <TabPanel><h3>Technology</h3>We are confident in saying that Dark Horse CPAs is the most tech-forward-thinking Accounting Firm in the country. DHPW is no different, it’s in our DNA. We employ numerous cutting-edge software solutions to help craft your custom financial plan. To be clear, that doesn’t mean we are going to drop a bunch of data in your lap and move on. The technology tools are largely for us, the science behind the scenes. The art of what we do is distilling the output into bite-sized action items that we implement alongside you.</TabPanel>
            <TabPanel><h3>Behavioral Finance Coaching</h3>Simply put, we help keep you on track when everything and everyone else is going off the rails. Remaining steadfast during periods of market volatility can greatly influence your portfolio's long-term performance. Concentrating on tactics like reducing expenses and taxes, optimizing asset allocation, ensuring adequate diversification, and maintaining discipline are even more important during the craziest of times.</TabPanel>
            <TabPanel><h3>Tax Strategies</h3>The evidence clearly points to future tax rate increases, so we have work to do now to minimize the impact of said increases. Your collaborative team of Dark Horse CPAs and Private Wealth Advisors will implement tax strategies on your behalf. Since we live under the same roof- we have more firepower than a standalone CPA or Wealth Management firm does. We’ll spare you the long list, but rest assured; it’s plentiful… and powerful.</TabPanel>
            <TabPanel><h3>Risk Management</h3>We know our way around insurance and we’re independent, so there is no need to worry about us hocking our own captive products. We’ll review your Life, Disability & Long Term Care Insurance solutions to make sure you aren’t wasting money on unnecessary coverage. On the flip side, if you’re underinsured, we’ll impartially shop dozens of companies/policies to find the best one to fit your needs.</TabPanel>
            <TabPanel><h3>Systematic Rebalancing</h3>Your investments will be reviewed monthly to make sure the original asset allocation we’ve agreed upon remains within its intended bands. If it gets out of whack, we will rebalance your assets, selling high and buying low.</TabPanel>
            <TabPanel><h3>Tax Loss Harvesting</h3>Tax loss harvesting is the ongoing process of selling positions that have experienced losses to offset a capital gain. The investment that is sold is replaced with another that has similar characteristics to maintain the expected risk and return profile. By realizing the loss, future capital gains are offset on a dollar-for-dollar basis.</TabPanel>
            <TabPanel><h3>Asset Location Optimization</h3>Investments generate different types of taxable gains and where you hold those investments has a sizable impact on your after-tax return. Also referred to as Tax Diversification, Asset Location focuses on matching assets with the ideal account type for minimal tax impact. Investments with higher expected returns are best held in account types that are tax-free or receive capital gains treatment, whereas interest-bearing investments that generate ordinary income are best housed in qualified accounts like IRAs.</TabPanel>
            <TabPanel><h3>Tax-Efficient Income Planning</h3>As investors, we delay instant gratification and save money so that some day in the future, we may reach financial independence and live a work-optional lifestyle. All that saving is not optimized unless you’re employing a tax-managed, time-segmented approach to designing an inflation-adjusted income plan to cover both your fixed and discretionary expenses. Whether you have reached financial independence or are years away, the time to start crafting this plan is now.</TabPanel>
            <TabPanel><h3>Estate Planning</h3>We coordinate with Estate Planning Professionals to help you design and implement your legacy goals. This includes providing for the surviving spouse, business succession planning, and philanthropic and generational wealth planning. If you have a taxable estate, we’ll bring you advanced strategies to mitigate your potential exposure.</TabPanel>
            <TabPanel><h3>Financial Planning</h3>At its core, financial planning is about helping you design what you want your future life to look like. That’s why your dedicated team takes an extensive amount of time to get to know you, your family, and your goals. From there, we back-cast the controllable set of actions we’ll guide you through today to increase the probability of success of your goals becoming reality.</TabPanel>
            <TabPanel><h3>Alternative Investments</h3>The average American investor is allocated almost 100 percent to a ‘diversified’ portfolio of public equities and debt. Conversely, in studying how wealthy people invest their capital, the most striking insight is that they only allocate about half of their assets to public markets, with the other half in alternative investments such as private real estate and private businesses (equity). True diversification utilizing alternative investments can serve to lessen risk while also potentially increasing returns. After conducting exhaustive due diligence, if appropriate, we’ll bring these opportunities to your portfolio so you can achieve broad diversification across both public and private markets like the most successful investors on the planet.</TabPanel>
            <TabPanel><h3>Ongoing, Proactive Support for Your Changing Needs</h3>Once your financial plan is up and running, we consistently monitor and adjust it as necessary to keep up with your ever-evolving life and goals. We touch base with you regularly to discuss how new regulations, economic factors and changes in your personal financial situation may impact your plans.</TabPanel>
            </div>
            </div>
          </div>
          </Tabs>
          </StyledContent>
        </div>
      </section>
      <section className="pt-20 pb-20 md:pt-32 md:pb-32 ">
      <div className="container relative md:-mt-[460px] mb-[150px]">
          <div className="relative -bottom-44 -mt-40 rounded-xl bg-secondary-100 px-4 py-6 md:px-16 md:py-16">
            <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-10">
              <div className="flex flex-col justify-between md:col-span-4 md:col-start-1">
                <div>
                  <div className="relative mx-auto mb-6 h-22 w-22 overflow-hidden rounded-full border-2 border-primary-500 md:mx-0 md:mb-8 md:h-auto md:w-auto md:overflow-visible md:rounded-none md:border-none md:pl-1.5 md:pt-1.5">
                    <div className="absolute left-0 top-0 hidden h-full w-full rounded-4xl bg-primary-500 md:block"></div>
                    <GatsbyImage
                      image={data.member.headshot.asset.gatsbyImageData}
                      className="z-0 rounded-full md:rounded-4xl"
                    />
                  </div>

                  {data.member.certifications.length > 0 && (
                    <div className="mb-8 hidden md:block">
                      <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                        Certifications
                      </div>
                      <ul className="styled-list-star">
                        {data.member.certifications.map((certification, i) => {
                          return <li key={i}>{certification.listItem}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
{/* 
                {data.member.hubspotID && (
                  <div className="hidden md:block">
                    <ButtonSolid
                      href={`/book-a-meeting/?hubspotID=${data.member.hubspotID}`}
                      text="Book a Meeting"
                      className="w-full"
                    />
                  </div>
                )} */}
              </div>
              <div className="md:col-span-8 md:col-end-13">
                <h2 className="heading-two text-center md:mb-8 md:text-left">
                  {data.member.name}
                </h2>

                <div className="mb-5 flex flex-wrap space-y-4 lg:space-y-0 lg:space-x-6">
                  {data.member.directPhoneNumber && (
                    <a
                      href={`tel:${data.member.directPhoneNumber
                        .replace(/[()]/g, "")
                        .replace(/ /g, "-")}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-primary-500 md:justify-start lg:w-auto"
                    >
                      <i className="fa-solid fa-phone mr-1.5 text-xl text-primary-500"></i>
                      <span className="font-semibold">
                        {data.member.directPhoneNumber}
                      </span>
                    </a>
                  )}

                  {data.member.emailAddress && (
                    <a
                      href={`mailto:${data.member.emailAddress}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-primary-500 md:justify-start lg:w-auto"
                    >
                      <i className="fa-solid fa-envelope mr-1.5 text-xl text-primary-500"></i>
                      <span className="font-semibold">
                        {data.member.emailAddress}
                      </span>
                    </a>
                  )}

                  {data.member.linkedinProfile && (
                    <a
                      href={data.member.linkedinProfile}
                      target="_blank"
                      rel="noreferrer"
                      className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-primary-500 md:justify-start lg:w-auto"
                    >
                      <i className="fab fa-linkedin mr-1.5 text-2xl text-primary-500"></i>
                      <span className="font-semibold">LinkedIn</span>
                    </a>
                  )}
                </div>

                <div className="mb-6 grid grid-cols-2 rounded-3xl border border-gray-900/40 bg-white text-center md:mb-10 md:flex md:rounded-lg md:text-left">
                  {data.member.title && (
                    <div
                      className={`border-b border-primary-900/40 py-5 px-4 md:flex-1 md:border-b-0 md:py-4`}
                    >
                      <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                        Title
                      </div>
                      <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                        {data.member.title}
                      </div>
                    </div>
                  )}

                  {data.member.hometown && (
                    <div
                      className={`border-l border-b border-primary-900/40 py-5 px-4 md:flex-1 md:border-b-0 md:py-4`}
                    >
                      <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                        Hometown
                      </div>
                      <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                        {data.member.hometown}
                      </div>
                    </div>
                  )}

                  {data.member.yearsExperience && (
                    <div
                      className={`border-primary-900/40 py-5 px-4 md:flex-1 md:py-4 ${data.member.hometown === null
                        ? "border-l border-b md:border-b-0"
                        : "md:border-l"
                        }`}
                    >
                      <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                        Years of Experience
                      </div>
                      <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                        {data.member.yearsExperience}
                      </div>
                    </div>
                  )}

                  {data.member.education.length > 0 && (
                    <div
                      className={`border-primary-900/40 py-5 px-4 md:flex-1 md:py-4 ${data.member.hometown === null &&
                        data.member.yearsExperience === null
                        ? "border-l"
                        : data.member.hometown === null ||
                          data.member.yearsExperience === null
                          ? "col-span-2 md:border-l"
                          : "border-l"
                        }`}
                    >
                      <div className="mb-1 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                        Education
                      </div>
                      <div
                        className={`grid items-center justify-center gap-x-3 md:justify-start md:gap-x-4 ${data.member.education.length > 1 && "grid-cols-2"
                          }`}
                      >
                        {data.member.education.map((education, i) => {
                          return (
                            <GatsbyImage
                              image={education.asset.gatsbyImageData}
                              className={`${data.member.education.length === 1 &&
                                "max-w-[90px]"
                                }`}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                {/* {data.member.hubspotID && (
                  <div className="mb-6 md:hidden">
                    <ButtonSolid
                    className=""
                      href={`/book-a-meeting/?hubspotID=${data.member.hubspotID}`}
                      text="Book a Meeting"
                      className="w-full"
                    />
                  </div>
                )} */}

                {data.member.quote && (
                  <figure>
                    <blockquote>
                      <q className="mb-4 block font-bold italic">
                        {data.member.quote}
                      </q>
                    </blockquote>
                    {data.member.quoteAuthor && (
                      <figcaption className="mb-4 -mt-2 block text-sm font-bold">
                        &mdash; {data.member.quoteAuthor}
                      </figcaption>
                    )}
                  </figure>
                )}

                {data.member._rawBiography && (
                  <>
                    <div className="relative mb-1 h-20 overflow-hidden">
                      <p className="mb-0">
                        {truncate(toPlainText(data.member._rawBiography))}
                      </p>
                      <div className="absolute left-0 bottom-0 h-full w-full bg-gradient-to-t from-secondary-100 to-white/10"></div>
                    </div>
                     <ButtonWithIcon
                      className="text-[#000000]"
                      icon="fa-solid fa-arrow-down"
                      modal="modal-team-members"
                      text="Read Full Bio"
                      altStyle={2}
                    /> 
                  </>
                )}

                {(data.member.specialties.length > 0 ||
                  data.member.industries.length > 0) && (
                    <div className="mt-10 hidden gap-x-4 gap-y-8 md:grid md:grid-cols-2">
                      {data.member.specialties.length > 0 && (
                        <div>
                          <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                            Specialties
                          </div>
                          <ul className="styled-list-plus">
                            {data.member.specialties.map((specialty, i) => {
                              return <li key={i}>{specialty.listItem}</li>;
                            })}
                          </ul>
                        </div>
                      )}

                      {data.member.industries.length > 0 && (
                        <div>
                          <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                            Industries
                          </div>
                          <ul className="styled-list-plus">
                            {data.member.industries.map((industry, i) => {
                              return <li key={i}>{industry.listItem}</li>;
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
</section>
      {/* break */}
      <ModalTeamMembers slideIndex={slideIndex} slides={teamMembersNodes} />
      {/* break */}
      <section className="bg-secondary-100 mb-0 zigZagBg">
        <div class="container py-[50px]">
          
          <CallToAction rotatingWords="Taxes" />
          </div>
      </section>
    
    </Layout>
  );
};

export const data = graphql`
  {
    member: sanityTeamMember(name: {eq: "Matt Poole"}) {
      id
      slug {
        current
      }
      name
      headshot {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 330)
        }
      }
      directPhoneNumber
      emailAddress
      linkedinProfile
      hubspotID
      title
      hometown
      yearsExperience
      education {
        asset {
          gatsbyImageData
        }
      }
      quote
      quoteAuthor
      _rawBiography
      certifications {
        listItem
      }
      specialties {
        listItem
      }
      industries {
        listItem
      }
    }
    teamMembers: allSanityTeamMember(filter: {name: {eq: "Matt Poole"}}) {
      edges {
        node {
          id
          name
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 564)
              title
            }
          }
          directPhoneNumber
          emailAddress
          linkedinProfile
          hubspotID
          _rawBiography
          title
        }
      }
    }
    serviceHero: file(relativePath: { eq: "philosophy/matt-quote.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    blockImage: file(relativePath: { eq: "homepage/Image.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageCoinStack: file(relativePath: { eq: "homepage/3-coinstack.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    homepageVenDiagram: file(relativePath: { eq: "homepage/2-intro.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 530)
      }
    }
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    homepageHeroCoins: file(
      relativePath: { eq: "homepage/hero-illustration-grouped.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 530)
      }
    }
    homepageHeroDesktop: file(
      relativePath: { eq: "locations/gray-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    subServices: allSanitySubService(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    sectionDivider: file(
      relativePath: { eq: "philosophy/section-divider.svg" }
    ) {
      publicURL
    }
    horseHeadBlue: file(relativePath: { eq: "global/logo-head-blue.svg" }) {
      publicURL
    }
    philosophyHeroUnderlay: file(
      relativePath: { eq: "philosophy/2-line background.svg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "foreign Tax/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 624)
      }
    }
    taxesCanBeStressful: file(
      relativePath: { eq: "Personal Taxes/taxes-can-be-stressful.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    team: file(relativePath: { eq: "Personal Taxes/team.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    compOut: file(relativePath: { eq: "foreign Tax/outbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    compIn: file(relativePath: { eq: "foreign Tax/inbound.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    taxes: file(relativePath: { eq: "Personal Taxes/taxes.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    homepageDictionary: file(
      relativePath: { eq: "homepage/4-dictionary.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 493)
      }
    }

    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    optimizeYourPipeline: file(
      relativePath: { eq: "3.0 Features/2. Optimize Your Pipeline.svg" }
    ) {
      publicURL
    }
    createEfficiency: file(
      relativePath: { eq: "3.0 Features/4.0 Create Efficiency.svg" }
    ) {
      publicURL
    }
    integrationAutomationReports: file(
      relativePath: {
        eq: "3.0 Features/5.0 Integration _ Automation Reports.svg"
      }
    ) {
      publicURL
    }
    success1: file(
      relativePath: { eq: "3.0 Features/3.0 Free Strategy Call.svg" }
    ) {
      publicURL
    }
    success2: file(
      relativePath: { eq: "3.0 Features/3.1 Dedicated Support Agent.svg" }
    ) {
      publicURL
    }
    success3: file(
      relativePath: { eq: "3.0 Features/3.2 Easy Ways To Connect With Us.svg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
